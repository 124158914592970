/**
 * Type: ページ
 * What: 団体利用LP
 */
import React from 'react';
import { scroller, animateScroll as scroll } from 'react-scroll';
import Layout from '../components/Layout';
import SEO from '../components/Seo';
import BreadcrumbComponent from '../components/Breadcrumb';
import AccordionButton from '../components/Accordion-button';
import Image from '../util/Image';
import SimpleSwiper from '../components/Slider.fade';

const pageTitle = 'BOSS E・ZO FUKUOKA団体ご利用方法';
const pageDescription = 'BOSS E・ZO FUKUOKA団体ご利用方法';
const pageSlug = 'group';

const SLIDER_USAGE_1 = [
  {
    filename: `img-group-usage-3.jpg`,
  },
  {
    filename: `img-group-usage-3-2.jpg`,
  },
];
const SLIDER_USAGE_2 = [
  {
    filename: `img-group-usage-3.jpg`,
  },
  {
    filename: `img-group-usage-3-2.jpg`,
  },
  {
    filename: `img-group-usage-3-3.jpg`,
  },
];
const SLIDER_USAGE_2_1 = [
  {
    filename: `img-group-usage-1.jpg`,
  },
  {
    filename: `img-group-usage-4.jpg`,
  },
];
const SLIDER_USAGE_2_2 = [
  {
    filename: `img-group-usage-5.jpg`,
  },
  {
    filename: `img-group-usage-3-2.jpg`,
  },
];
const SLIDER_USAGE_2_3 = [
  {
    filename: `img-group-usage-5.jpg`,
  },
  {
    filename: `img-group-usage-3-3.jpg`,
  },
  {
    filename: `img-group-usage-3-2.jpg`,
  },
];
const SLIDER_USAGE_3 = [
  {
    filename: `img-group-usage-7.jpg`,
  },
  {
    filename: `img-group-usage-7-2.jpg`,
  },
];
const SLIDER_USAGE_4 = [
  {
    filename: `img-group-usage-6.jpg`,
  },
  {
    filename: `img-group-usage-9.jpg`,
  },
];

// Data Props Template
const ticketHowPage = () => {
  const ankerLinkScroll = (e, elem, offset) => {
    e.preventDefault();
    let $offset = offset || -160;
    if (window.matchMedia('screen and (max-width: 768px)').matches) {
      $offset = offset || -110;
    }
    scroller.scrollTo(elem, {
      duration: 1200,
      delay: 0,
      offset: $offset,
      smooth: 'easeOutQuint',
    });
  };
  return (
    <Layout pageSlug={pageSlug}>
      <SEO title={pageTitle} description={pageDescription} />
      <BreadcrumbComponent hierarchy={2} bread2title={pageTitle} />
      <section className="section">
        <div className="container">
          <div className="inner-slim trans">
            <div className="mb-3 pc-mb20">
              <Image src="main-group.jpg" />
            </div>
            <p className="text is-3 is-3-sp">
              下記内容をご確認いただきページ下部記載の予約フォームよりご予約ください。
            </p>
            <p className="list-mark">
              ※団体のお申込みは11名以上からの受付、20名様以上から割引対象となります。
            </p>
            <p className="list-mark">※事前のお振込みが必要です。</p>
            <div className="btn-wrap sp-anker-button-wrap mt-5">
              <a
                href="#unkoDiscountTicket"
                className="btn-regular"
                onClick={(e) => ankerLinkScroll(e, 'groupReservation')}
                onKeyDown={(e) => ankerLinkScroll(e, 'groupReservation')}
              >
                <span>ご予約方法</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="19"
                  height="14"
                  viewBox="0 0 18.438 14.47"
                  className="icon-anker"
                >
                  <path
                    id="anker-arrow.svg"
                    className="cls-1"
                    d="M402.711,2438.44v-6.12a0.713,0.713,0,0,0-1.152-.57l-7.628,6.15a0.7,0.7,0,0,1-.883,0l-7.606-6.13a0.715,0.715,0,0,0-1.154.57v6.13a0.739,0.739,0,0,0,.271.57l8.489,6.83a0.7,0.7,0,0,0,.883,0l8.51-6.86a0.709,0.709,0,0,0,.27-0.57"
                    transform="translate(-384.281 -2431.59)"
                  />
                </svg>
              </a>
              <a
                href="#unkoDiscountTicket"
                className="btn-regular"
                onClick={(e) => ankerLinkScroll(e, 'groupUsageExample')}
                onKeyDown={(e) => ankerLinkScroll(e, 'groupUsageExample')}
              >
                <span>ご利用例</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="19"
                  height="14"
                  viewBox="0 0 18.438 14.47"
                  className="icon-anker"
                >
                  <path
                    id="anker-arrow.svg"
                    className="cls-1"
                    d="M402.711,2438.44v-6.12a0.713,0.713,0,0,0-1.152-.57l-7.628,6.15a0.7,0.7,0,0,1-.883,0l-7.606-6.13a0.715,0.715,0,0,0-1.154.57v6.13a0.739,0.739,0,0,0,.271.57l8.489,6.83a0.7,0.7,0,0,0,.883,0l8.51-6.86a0.709,0.709,0,0,0,.27-0.57"
                    transform="translate(-384.281 -2431.59)"
                  />
                </svg>
              </a>
              <a
                href="#unkoDiscountTicket"
                className="btn-regular"
                onClick={(e) => ankerLinkScroll(e, 'groupDiscount')}
                onKeyDown={(e) => ankerLinkScroll(e, 'groupDiscount')}
              >
                <span>料金・割引について</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="19"
                  height="14"
                  viewBox="0 0 18.438 14.47"
                  className="icon-anker"
                >
                  <path
                    id="anker-arrow.svg"
                    className="cls-1"
                    d="M402.711,2438.44v-6.12a0.713,0.713,0,0,0-1.152-.57l-7.628,6.15a0.7,0.7,0,0,1-.883,0l-7.606-6.13a0.715,0.715,0,0,0-1.154.57v6.13a0.739,0.739,0,0,0,.271.57l8.489,6.83a0.7,0.7,0,0,0,.883,0l8.51-6.86a0.709,0.709,0,0,0,.27-0.57"
                    transform="translate(-384.281 -2431.59)"
                  />
                </svg>
              </a>
            </div>
            <p className="mt-5 tac text is-2 is-2-sp">
              <a
                href="https://e-zofukuoka.com/news/event/2024062126872/"
                className="tx-link"
              >
                【6/28（金）~2025年2月28日（金）予定】ハリーとハニーのナゾトキクエスト
                消えた若鷹の証
              </a>
            </p>
          </div>
        </div>
      </section>
      <section className="section">
        <div className="container">
          <h3 className="title is-3" id="groupReservation">
            <span>ご予約方法</span>
          </h3>
          <div className="inner-slim ">
            <p className="text is-1 is-3-sp tac">
              下記予約フォームよりお申し込みいただけます。
            </p>
            <div className="btn-wrap mt-5">
              <a href="/inquiry/group" className="btn-regular tac">
                <span>
                  BOSS E・ZO FUKUOKA
                  <br />
                  団体予約フォーム
                </span>
                <svg
                  viewBox="0 0 490.8 490.8"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M135.685,3.128c-4.237-4.093-10.99-3.975-15.083,0.262c-3.992,4.134-3.992,10.687,0,14.82l227.115,227.136L120.581,472.461c-4.237,4.093-4.354,10.845-0.262,15.083c4.093,4.237,10.845,4.354,15.083,0.262c0.089-0.086,0.176-0.173,0.262-0.262l234.667-234.667c4.164-4.165,4.164-10.917,0-15.083L135.685,3.128z" />
                  <path d="M128.133,490.68c-5.891,0.011-10.675-4.757-10.686-10.648c-0.005-2.84,1.123-5.565,3.134-7.571l227.136-227.115L120.581,18.232c-4.171-4.171-4.171-10.933,0-15.104c4.171-4.171,10.933-4.171,15.104,0l234.667,234.667c4.164,4.165,4.164,10.917,0,15.083L135.685,487.544C133.685,489.551,130.967,490.68,128.133,490.68z" />
                </svg>
              </a>
            </div>
            <p className="list-mark mt-6">
              ※団体のお申込みは11名以上からの受付、20名様以上から割引対象となります。
              <br className="sp" />
              （みずほPayPayドームツアー
              （ドーム満喫コース）は8名様からとなります。）
            </p>
            <p className="list-mark">
              ※ご予約は、お申込み日の3週間先以降から（お支払いにクーポンご利用の旅行会社様は、2週間先以降から）となります。
            </p>
            <p className="list-mark">
              ※お申込み後、弊社からの受付確認メールをもって、申込（受付）完了となります。（メール受信から3営業日以内に返信いたします。）
            </p>
            <p className="list-mark">
              ※団体バス駐車場のご予約は団体予約フォーム内よりお申し込みいただけます。
            </p>
            <p className="list-mark">
              ※ドームツアーのご予約は
              <a
                className="tx-link"
                href="https://dometour.softbankhawks.co.jp/"
                target="_blank"
                rel="noopener noreferrer"
              >
                こちら
              </a>
            </p>
            <p className="mt-5">
              お問合せ・プランニングは BOSS E・ZO FUKUOKA
              ／みずほPayPayドームツアー
              予約担当窓口までお気軽にお問い合わせください。
            </p>
            <p>
              メール：&#83;&#66;&#72;&#71;&#82;P&#45;&#101;&#122;&#111;&#102;u&#107;u&#111;&#107;a&#95;&#114;&#101;&#115;&#101;&#114;v&#97;&#116;i&#111;&#110;&#64;&#103;.&#115;&#111;&#102;&#116;&#98;a&#110;k.&#99;&#111;&#46;j&#112;
              <br />
              （メール受信から3営業日以内に返信いたします。お急ぎの場合はお電話にてお問合せください。）
            </p>
            <p>
              TEL：092-847-1699（平日10:00～13:00、14:00～18：00）土日祝日休業
            </p>
          </div>
        </div>
      </section>
      <section className="section">
        <div className="container">
          <h4 className="title is-3 mb0" id="groupUsageExample">
            <span>実際のご利用例</span>
          </h4>
          <div className="inner-slim trans">
            <h4 className="ttl-strip">高等学校修学旅行（150名様）</h4>
            <p className="text is-2 is-3-sp">
              アトラクション2種と昼食（お弁当）を組み合わせたプラン。（ご滞在時間約3時間）
            </p>
            <div className="flex mb-5">
              <div className="alphabet-icon-wrap">
                <Image src="img-group-usage-1.jpg" />
                <p className="logo text is-2 is-3-sp tac">
                  チームラボフォレスト福岡
                </p>
              </div>
              <div className="alphabet-icon-wrap">
                <Image src="img-group-usage-2.jpg" />
                <p className="logo text is-2 is-3-sp tac">ドームツアー</p>
              </div>
              <div className="alphabet-icon-wrap">
                <SimpleSwiper data={SLIDER_USAGE_1} />
                <p className="logo text is-2 is-3-sp tac">
                  ドームスタンド席でお弁当
                </p>
              </div>
            </div>
            <AccordionButton title="詳しく見る" targetId="accordionButton1">
              <p className="text is-2 is-3-sp fwb">
                ■スケジュール（ご滞在時間約3時間）
              </p>
              <p className="text is-1 is-2-sp">
                2グループに分かれて効率よくアトラクションを体験、その後皆さんで昼食
              </p>
              <div className="flex mt-5">
                <div className="alphabet-icon-wrap">
                  <Image src="img-group-usage-2.jpg" />
                  <div className="alphabet-icon">１</div>
                  <div className="logo">
                    <p className="text is-2 is-3-sp tac">ドームツアー</p>
                  </div>
                </div>
                <div className="alphabet-icon-wrap">
                  <Image src="img-group-usage-1.jpg" />
                  <div className="alphabet-icon">２</div>
                  <div className="logo">
                    <p className="text is-2 is-3-sp tac">チームラボ</p>
                  </div>
                </div>
                <div className="alphabet-icon-wrap">
                  <SimpleSwiper data={SLIDER_USAGE_2} />
                  <div className="alphabet-icon">３</div>
                  <div className="logo">
                    <p className="text is-2 is-3-sp tac">スタンド席でお弁当</p>
                    <p className="list-mark tac mb-1">お弁当：1,200円〜</p>
                    <p className="list-mark tac mb0">※アレルギー対応弁当あり</p>
                  </div>
                </div>
              </div>
              <div className="ticket-table mt-5">
                <div className="table-base price-table">
                  <table>
                    <tbody className="pc">
                      <tr>
                        <th> </th>
                        <th>11:00〜</th>
                        <th>12:00〜</th>
                        <th>13:00〜</th>
                      </tr>
                      <tr>
                        <th>Aグループ 80人（2クラス）</th>
                        <td className="tac">①ドームツアー</td>
                        <td className="tac">②チームラボ</td>
                        <td className="tac">③スタンド席でお弁当</td>
                      </tr>
                      <tr>
                        <th>Bグループ 70人（2クラス）</th>
                        <td className="tac">②チームラボ</td>
                        <td className="tac">①ドームツアー</td>
                        <td className="tac">③スタンド席でお弁当</td>
                      </tr>
                    </tbody>
                    <tbody className="sp">
                      <tr>
                        <th colSpan="2">Aグループ 80人（2クラス）</th>
                      </tr>
                      <tr>
                        <td>11:00〜</td>
                        <td>①ドームツアー</td>
                      </tr>
                      <tr>
                        <td>12:00〜</td>
                        <td>②チームラボ</td>
                      </tr>
                      <tr>
                        <td>13:00〜</td>
                        <td>③スタンド席でお弁当</td>
                      </tr>
                      <tr>
                        <th colSpan="2">Bグループ 70人（2クラス）</th>
                      </tr>
                      <tr>
                        <td>11:00〜</td>
                        <td>②チームラボ</td>
                      </tr>
                      <tr>
                        <td>12:00〜</td>
                        <td>①ドームツアー</td>
                      </tr>
                      <tr>
                        <td>13:00〜</td>
                        <td>③スタンド席でお弁当</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <p className="text is-2 is-3-sp fwb mt-5">■料金</p>
              <p className="text is-1 is-2-sp">
                4,600円／人～4,800円／人 (※団体割引料金適用)
              </p>
            </AccordionButton>
            {/* 高等学校修学旅行（150名様）End */}
            {/* 中学校修学旅行（80名様） */}
            <h4 className="ttl-strip mt-5">中学校修学旅行（80名様）</h4>
            <p className="text is-2 is-3-sp">
              アトラクション1種と夕食（お弁当）を組み合わせたプラン。（ご滞在時間約2時間）
            </p>
            <div className="flex mb-5" style={{ justifyContent: 'center' }}>
              <div className="alphabet-icon-wrap half">
                <SimpleSwiper data={SLIDER_USAGE_2_1} />
                <p className="logo text is-2 is-3-sp tac">
                  「チームラボフォレスト福岡」または「V-World30分」
                </p>
              </div>
            </div>
            <AccordionButton title="詳しく見る" targetId="accordionButton2">
              <p className="text is-2 is-3-sp fwb">
                ■スケジュール（ご滞在時間約3時間）
              </p>
              <p className="text is-1 is-2-sp">
                2グループに分かれて効率よくアトラクションを体験、その後皆さんで昼食
              </p>
              <div className="flex mt-5">
                <div className="alphabet-icon-wrap">
                  <SimpleSwiper data={SLIDER_USAGE_2_3} />
                  <div className="alphabet-icon">１</div>
                  <div className="logo">
                    <p className="text is-2 is-3-sp tac">鷹正でお弁当</p>
                    <p className="list-mark tac mb-1">お弁当：1,200円〜</p>
                    <p className="list-mark tac mb0">※アレルギー対応弁当あり</p>
                  </div>
                </div>
                <div className="alphabet-icon-wrap">
                  <Image src="img-group-usage-1.jpg" />
                  <div className="alphabet-icon">２</div>
                  <div className="logo">
                    <p className="text is-2 is-3-sp tac">チームラボ</p>
                  </div>
                </div>
                <div className="alphabet-icon-wrap">
                  <Image src="img-group-usage-4.jpg" />
                  <div className="alphabet-icon">３</div>
                  <div className="logo">
                    <p className="text is-2 is-3-sp tac">V-World30分</p>
                  </div>
                </div>
              </div>
              <div className="ticket-table mt-5">
                <div className="table-base price-table">
                  <table>
                    <tbody className="pc">
                      <tr>
                        <th> </th>
                        <th>17:00〜</th>
                        <th>18:00〜</th>
                      </tr>
                      <tr>
                        <th>Aグループ 40人（1クラス）</th>
                        <td className="tac">①鷹正でお弁当</td>
                        <td className="tac">②チームラボ</td>
                      </tr>
                      <tr>
                        <th>Bグループ 40人（1クラス）</th>
                        <td className="tac">①鷹正でお弁当</td>
                        <td className="tac">③V-World30分</td>
                      </tr>
                    </tbody>
                    <tbody className="sp">
                      <tr>
                        <th colSpan="2">Aグループ 40人（1クラス）</th>
                      </tr>
                      <tr>
                        <td>17:00〜</td>
                        <td>①鷹正でお弁当</td>
                      </tr>
                      <tr>
                        <td>18:00〜</td>
                        <td>②チームラボ</td>
                      </tr>
                      <tr>
                        <th colSpan="2">Bグループ 40人（1クラス）</th>
                      </tr>
                      <tr>
                        <td>17:00〜</td>
                        <td>①鷹正でお弁当</td>
                      </tr>
                      <tr>
                        <td>18:00〜</td>
                        <td>③V-World30分</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <p className="text is-2 is-3-sp fwb mt-5">■料金</p>
              <p className="text is-1 is-2-sp">
                2,200円／人～2,300円／人 (※団体割引料金適用)
              </p>
            </AccordionButton>
            {/* 中学校修学旅行（80名様）End */}
            {/* 社員旅行（30名様） */}
            <h4 className="ttl-strip mt-5">社員旅行（30名様）</h4>
            <p className="text is-2 is-3-sp">
              アトラクション1種と昼食（MLBCafé）を組み合わせたプラン。（ご滞在時間約2時間）
            </p>
            <div className="flex mb-5">
              <div className="alphabet-icon-wrap half">
                <Image src="img-group-usage-6.jpg" />
                <p className="logo text is-2 is-3-sp tac">
                  王貞治ベースボールミュージアム
                </p>
              </div>
              <div className="alphabet-icon-wrap half">
                <SimpleSwiper data={SLIDER_USAGE_3} />
                <p className="logo text is-2 is-3-sp tac">
                  E・ZO内店舗（MLBCafé）でランチ
                </p>
              </div>
            </div>
            <AccordionButton title="詳しく見る" targetId="accordionButton3">
              <p className="text is-2 is-3-sp fwb">
                ■スケジュール（ご滞在時間約2時間）
              </p>
              <p className="text is-1 is-2-sp">
                アトラクションを体験、その後皆さんで昼食
              </p>
              <div className="flex mt-5">
                <div className="alphabet-icon-wrap half">
                  <Image src="img-group-usage-6.jpg" />
                  <div className="alphabet-icon">１</div>
                  <div className="logo">
                    <p className="text is-2 is-3-sp tac">
                      王貞治ベースボールミュージアム
                    </p>
                  </div>
                </div>
                <div className="alphabet-icon-wrap half">
                  <SimpleSwiper data={SLIDER_USAGE_3} />
                  <div className="alphabet-icon">２</div>
                  <div className="logo">
                    <p className="text is-2 is-3-sp tac">MLB caféでランチ</p>
                    <p className="list-mark tac mb-1">
                      バーガーランチ：1,500円〜
                    </p>
                  </div>
                </div>
              </div>
              <div className="ticket-table mt-5">
                <div className="table-base price-table">
                  <table>
                    <tbody className="pc">
                      <tr>
                        <th> </th>
                        <th>11:00〜</th>
                        <th>12:00〜</th>
                      </tr>
                      <tr>
                        <th>グループ 30人</th>
                        <td className="tac">①王貞治ベースボールミュージアム</td>
                        <td className="tac">②MLB caféでランチ</td>
                      </tr>
                    </tbody>
                    <tbody className="sp">
                      <tr>
                        <th colSpan="2">グループ 30人</th>
                      </tr>
                      <tr>
                        <td>11:00〜</td>
                        <td>①王貞治ベースボールミュージアム</td>
                      </tr>
                      <tr>
                        <td>12:00〜</td>
                        <td>②MLB caféでランチ</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <p className="text is-2 is-3-sp fwb mt-5">■料金</p>
              <p className="text is-1 is-2-sp">
                3,100円／人～4,000円／人 (※団体割引料金適用)
              </p>
            </AccordionButton>
            {/* 社員旅行（30名様）End */}
            <p className="has-text-align-center has-text-weight-bold sp-mb100 text is-5 mt-6">
              ご要望に応じて、様々な組み合わせを
              <br />
              ご提案いたしますのでお気軽にご相談ください！
            </p>
          </div>
        </div>
      </section>
      <section className="section">
        <div className="container">
          <h4 className="title is-3" id="groupDiscount">
            <span>ご利用料金の割引につきまして</span>
          </h4>
          <div className="inner-slim">
            <ul>
              <li>
                大人・小人に関わらず、1,500円以上のチケットは定価より200円引き
              </li>
              <li>1,000円～1,499円までのチケットは定価より100円引き</li>
              <li className="mb-5">1,000円未満のチケットは割引無し</li>
              <li className="mb-1 is-10px text">
                下記の特定日につきましては団体割引対象外の日程でございます。
                <br />
                団体予約をご希望の方はお問い合わせをお願いいたします。
              </li>
              <li className="is-10px text">4/27（土）～4/30（火）</li>
              <li className="is-10px text">5/1（水）～5/6（月祝）</li>
              <li className="is-10px text">8/10（土）～8/18（日）</li>
              <li className="is-10px text">12/28（土）～12/31（火）</li>
              <li className="is-10px text">1/1（水祝）～1/5（日）</li>
            </ul>
          </div>
        </div>
      </section>
      <section className="section">
        <div className="container">
          <h4 className="title is-3 pc-mb-6 pb-5 sp-mb0">
            <span>
              団体予約対象アトラクション、
              <br className="sp" />
              ご利用料金、所要時間
            </span>
          </h4>
          <div className="inner-slim trans">
            <div className="slime-table">
              <div className="ticket-table pb-6">
                <div className="pc-mb50">
                  <h4 className="floor-ttl title is-6 fwb tac mb-4 sp-mb0">
                    <span className="mb0 floorNo">
                      <span>R</span>
                      <span>F</span>
                    </span>
                    <a href="/zekkei-brothers/subezo/" className="tx-link">
                      絶景3兄弟 すべZO SMBC 日興証券
                    </a>
                  </h4>
                  <div className="flex mt-5">
                    <Image
                      src="img-group-usage-8.jpg"
                      className="half sp-mb10"
                    />
                    <div className="half flex-justify-center">
                      <p className="text is-2 is-3-sp mb-2">
                        地上40mの高さからビルの壁面に沿って地上まで一気に滑り降りる全長100mのチューブ型スライダー。体験できるのは日本でBOSS
                        E･ZO FUKUOKAだけ！
                      </p>
                      <p className="list-mark">
                        ※建造物に付随したチューブ型スライダーとして日本初
                      </p>
                      <p className="list-mark mt-5">
                        ［所要時間：1時間あたり約40名。体験は1名1回となります。］
                      </p>
                    </div>
                  </div>
                  <div className="table-base price-table mt-5">
                    <table>
                      <tbody className="pc">
                        <tr>
                          <th>料金</th>
                        </tr>
                        <tr>
                          <td>
                            1,100円
                            <br />
                            （定価1,200円）
                          </td>
                        </tr>
                      </tbody>
                      <tbody className="sp">
                        <tr>
                          <th>料金</th>
                        </tr>
                        <tr>
                          <td>
                            900円
                            <br />
                            （定価1,000円）
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="btn-wrap mt-5 mb-5">
                    <a href="/zekkei-brothers/subezo/" className="btn-regular">
                      <span>詳細・注意事項はこちら</span>
                      <svg
                        viewBox="0 0 490.8 490.8"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M135.685,3.128c-4.237-4.093-10.99-3.975-15.083,0.262c-3.992,4.134-3.992,10.687,0,14.82l227.115,227.136L120.581,472.461c-4.237,4.093-4.354,10.845-0.262,15.083c4.093,4.237,10.845,4.354,15.083,0.262c0.089-0.086,0.176-0.173,0.262-0.262l234.667-234.667c4.164-4.165,4.164-10.917,0-15.083L135.685,3.128z" />
                        <path d="M128.133,490.68c-5.891,0.011-10.675-4.757-10.686-10.648c-0.005-2.84,1.123-5.565,3.134-7.571l227.136-227.115L120.581,18.232c-4.171-4.171-4.171-10.933,0-15.104c4.171-4.171,10.933-4.171,15.104,0l234.667,234.667c4.164,4.165,4.164,10.917,0,15.083L135.685,487.544C133.685,489.551,130.967,490.68,128.133,490.68z" />
                      </svg>
                    </a>
                  </div>
                </div>
                <div className="pt-6 pc-mb50">
                  <h4 className="floor-ttl title is-6 fwb tac mb-4 sp-mb0">
                    <span className="floorNo mb0">
                      <span>6</span>
                      <span>F</span>
                    </span>
                    <a href="/v-world/" className="tx-link">
                      V-World AREA クレディ・アグリコル
                    </a>
                  </h4>
                  <div className="flex mt-5">
                    <Image
                      src="img-group-usage-4.jpg"
                      className="half sp-mb10"
                    />
                    <div className="half flex-justify-center">
                      <p className="text is-2 is-3-sp mb-2">
                        和の雰囲気に彩られた空間で、様々なバーチャルコンテンツが体験できる最新施設！
                      </p>
                    </div>
                  </div>
                  <div className="table-base price-table mt-5">
                    <table>
                      <tbody className="pc">
                        <tr>
                          <th>コース</th>
                          <th>23歳以上</th>
                          <th>22歳～13歳</th>
                          <th>12歳以下</th>
                        </tr>
                        <tr>
                          <td>2時間</td>
                          <td>
                            2,600円
                            <br />
                            （通常価格2,800円）
                          </td>
                          <td>
                            2,000円
                            <br />
                            （通常価格2,200円）
                          </td>
                          <td>
                            1,500円 ※<sup>1</sup>
                            <br />
                            （通常価格1,700円）
                          </td>
                        </tr>
                        <tr>
                          <td>1時間</td>
                          <td>
                            1,800円
                            <br />
                            （通常価格2,000円）
                          </td>
                          <td>
                            1,400円
                            <br />
                            （通常価格1,600円）
                          </td>
                          <td>
                            900円 ※<sup>1</sup>
                            <br />
                            （通常価格1,000円）
                          </td>
                        </tr>
                      </tbody>
                      <tbody className="sp">
                        <tr>
                          <th colSpan="2">料金区分</th>
                        </tr>
                        <tr className="sub">
                          <th colSpan="2">2時間コース</th>
                        </tr>
                        <tr>
                          <td>23歳以上</td>
                          <td>
                            2,600円
                            <br />
                            （通常価格2,800円）
                          </td>
                        </tr>
                        <tr>
                          <td>13~22歳</td>
                          <td>
                            2,000円
                            <br />
                            （通常価格2,200円）
                          </td>
                        </tr>
                        <tr>
                          <td>12歳以下</td>
                          <td>
                            1,500円 ※<sup>1</sup>
                            <br />
                            （通常価格1,700円）
                          </td>
                        </tr>
                        <tr className="sub">
                          <th colSpan="2">1時間コース</th>
                        </tr>
                        <tr>
                          <td>23歳以上</td>
                          <td>
                            1,800円
                            <br />
                            （通常価格2,000円）
                          </td>
                        </tr>
                        <tr>
                          <td>13~22歳</td>
                          <td>
                            1,400円
                            <br />
                            （通常価格1,600円）
                          </td>
                        </tr>
                        <tr>
                          <td>12歳以下</td>
                          <td>
                            900円 ※<sup>1</sup>
                            <br />
                            （通常価格1,000円）
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="btn-wrap mt-5 mb-5">
                    <a href="/v-world/" className="btn-regular">
                      <span>詳細・注意事項はこちら</span>
                      <svg
                        viewBox="0 0 490.8 490.8"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M135.685,3.128c-4.237-4.093-10.99-3.975-15.083,0.262c-3.992,4.134-3.992,10.687,0,14.82l227.115,227.136L120.581,472.461c-4.237,4.093-4.354,10.845-0.262,15.083c4.093,4.237,10.845,4.354,15.083,0.262c0.089-0.086,0.176-0.173,0.262-0.262l234.667-234.667c4.164-4.165,4.164-10.917,0-15.083L135.685,3.128z" />
                        <path d="M128.133,490.68c-5.891,0.011-10.675-4.757-10.686-10.648c-0.005-2.84,1.123-5.565,3.134-7.571l227.136-227.115L120.581,18.232c-4.171-4.171-4.171-10.933,0-15.104c4.171-4.171,10.933-4.171,15.104,0l234.667,234.667c4.164,4.165,4.164,10.917,0,15.083L135.685,487.544C133.685,489.551,130.967,490.68,128.133,490.68z" />
                      </svg>
                    </a>
                  </div>
                </div>
                <div className="pt-6 pc-mb50">
                  <h4 className="floor-ttl title is-6 fwb tac mb-4 sp-mb0">
                    <span className="floorNo mb0">
                      <span>5</span>
                      <span>F</span>
                    </span>
                    <a href="/teamlabforest/" className="tx-link">
                      チームラボフォレスト 福岡 - SBI証券
                    </a>
                  </h4>
                  <div className="flex mt-5">
                    <Image
                      src="img-group-usage-1.jpg"
                      className="half sp-mb10"
                    />
                    <div className="half flex-justify-center">
                      <p className="text is-2 is-3-sp mb-2">
                        チームラボフォレストは、アートコレクティブ・チームラボによる「捕まえて集める森」と「運動の森」からなる新しいミュージアムです。
                      </p>
                      <p className="text is-2 is-3-sp mt-5">
                        ［所要時間：約1時間］
                      </p>
                    </div>
                  </div>
                  <div className="table-base price-table mt-5">
                    <table>
                      <tbody className="pc">
                        <tr>
                          <th>16歳以上</th>
                          <th>15歳以下</th>
                        </tr>
                        <tr>
                          <td>
                            2,200円
                            <br />
                            （通常価格2,400円）
                          </td>
                          <td>
                            900円※<sup>2</sup>
                            <br />
                            （通常価格1,000円）
                          </td>
                        </tr>
                      </tbody>
                      <tbody className="sp">
                        <tr>
                          <th colSpan="2">料金区分</th>
                        </tr>
                        <tr>
                          <td>16歳以上</td>
                          <td>
                            2,200円
                            <br />
                            （通常価格2,400円）
                          </td>
                        </tr>
                        <tr>
                          <td>15歳以下</td>
                          <td>
                            900円※<sup>2</sup>
                            <br />
                            （通常価格1,000円）
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <p className="u-mt-15 u-pc:ta-c">
                    高校生、大学生、専門学生は、学生料金2000円が適用となります。
                    <br />
                    （アトラクションの入場口で学生証の提示が必要です。）
                  </p>

                  <div className="btn-wrap mt-5 mb-5">
                    <a href="/teamlabforest/" className="btn-regular">
                      <span>詳細・注意事項はこちら</span>
                      <svg
                        viewBox="0 0 490.8 490.8"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M135.685,3.128c-4.237-4.093-10.99-3.975-15.083,0.262c-3.992,4.134-3.992,10.687,0,14.82l227.115,227.136L120.581,472.461c-4.237,4.093-4.354,10.845-0.262,15.083c4.093,4.237,10.845,4.354,15.083,0.262c0.089-0.086,0.176-0.173,0.262-0.262l234.667-234.667c4.164-4.165,4.164-10.917,0-15.083L135.685,3.128z" />
                        <path d="M128.133,490.68c-5.891,0.011-10.675-4.757-10.686-10.648c-0.005-2.84,1.123-5.565,3.134-7.571l227.136-227.115L120.581,18.232c-4.171-4.171-4.171-10.933,0-15.104c4.171-4.171,10.933-4.171,15.104,0l234.667,234.667c4.164,4.165,4.164,10.917,0,15.083L135.685,487.544C133.685,489.551,130.967,490.68,128.133,490.68z" />
                      </svg>
                    </a>
                  </div>
                </div>
                <div className="pt-6 pc-mb50">
                  <h4 className="floor-ttl title is-6 fwb tac mb-4 sp-mb0">
                    <span className="floorNo mb0">
                      <span>4</span>
                      <span>F</span>
                    </span>
                    <a href="/obm/" className="tx-link">
                      王貞治ベースボールミュージアム
                      <br />
                      Supported by DREAM ORDER
                    </a>
                  </h4>
                  <div className="flex mt-5">
                    <div className="half sp-mb10">
                      <SimpleSwiper data={SLIDER_USAGE_4} />
                    </div>
                    <div className="half flex-justify-center">
                      <p className="text is-2 is-3-sp">
                        <span className="fwb">【ヒストリーゾーン】</span>
                        野球人・王貞治の球史を振り返る展示品や映像を最新の手法で表現。王さんの波乱万丈の道のりを描きます。
                      </p>
                      <p className="text is-2 is-3-sp mt-5">
                        <span className="fwb">【89パーク】</span>
                        王さんの野球への想いを体験型アトラクションに凝縮。打つ、投げる、捕るなど様々なチャレンジが楽しめます。
                      </p>
                      <p className="text is-2 is-3-sp mt-5">
                        ［所要時間：約1.5時間］
                      </p>
                    </div>
                  </div>
                  <div className="table-base price-table mt-5">
                    <table>
                      <tbody className="pc">
                        <tr>
                          <th>16歳以上</th>
                          <th>15歳以下</th>
                        </tr>
                        <tr>
                          <td>
                            1,600円
                            <br />
                            （通常価格1,800円）
                          </td>
                          <td>
                            900 円 ※<sup>3</sup>
                          </td>
                        </tr>
                      </tbody>
                      <tbody className="sp">
                        <tr>
                          <th colSpan="2">料金区分</th>
                        </tr>
                        <tr>
                          <td>16歳以上</td>
                          <td>
                            1,600円
                            <br />
                            （通常価格1,800円）
                          </td>
                        </tr>
                        <tr>
                          <td>15歳以下</td>
                          <td>
                            900 円 ※<sup>3</sup>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="btn-wrap mt-5 mb-5">
                    <a href="/obm/" className="btn-regular">
                      <span>詳細・注意事項はこちら</span>
                      <svg
                        viewBox="0 0 490.8 490.8"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M135.685,3.128c-4.237-4.093-10.99-3.975-15.083,0.262c-3.992,4.134-3.992,10.687,0,14.82l227.115,227.136L120.581,472.461c-4.237,4.093-4.354,10.845-0.262,15.083c4.093,4.237,10.845,4.354,15.083,0.262c0.089-0.086,0.176-0.173,0.262-0.262l234.667-234.667c4.164-4.165,4.164-10.917,0-15.083L135.685,3.128z" />
                        <path d="M128.133,490.68c-5.891,0.011-10.675-4.757-10.686-10.648c-0.005-2.84,1.123-5.565,3.134-7.571l227.136-227.115L120.581,18.232c-4.171-4.171-4.171-10.933,0-15.104c4.171-4.171,10.933-4.171,15.104,0l234.667,234.667c4.164,4.165,4.164,10.917,0,15.083L135.685,487.544C133.685,489.551,130.967,490.68,128.133,490.68z" />
                      </svg>
                    </a>
                  </div>
                </div>
                <div className="pt-6 pc-mb50">
                  <h4 className="floor-ttl title is-6 fwb tac mb-4 sp-mb0">
                    <span className="floorNo mb0">
                      <span>4</span>
                      <span>F</span>
                    </span>
                    <a href="/obm/89park/" className="tx-link">
                      王貞治ベースボールミュージアム
                      <br />
                      Supported by DREAM ORDER
                      <br />
                      「89パーク」
                    </a>
                  </h4>
                  <div className="flex mt-5">
                    <Image
                      src="img-group-usage-9.jpg"
                      className="half sp-mb10"
                    />
                    <div className="half flex-justify-center">
                      <p className="text is-2 is-3-sp mb-2">
                        王さんの野球への想いを体験型アトラクションに凝縮。打つ、投げる、捕るなど様々なチャレンジが楽しめます。
                      </p>
                      <p className="text is-2 is-3-sp mt-5">
                        ［所要時間：約1時間］
                      </p>
                    </div>
                  </div>
                  <div className="table-base price-table mt-5">
                    <table>
                      <tbody className="pc">
                        <tr>
                          <th>16歳以上</th>
                          <th>15歳以下</th>
                        </tr>
                        <tr>
                          <td>
                            900円
                            <br />
                            （通常価格1,000円）
                          </td>
                          <td>
                            500 円 ※<sup>3</sup>
                          </td>
                        </tr>
                      </tbody>
                      <tbody className="sp">
                        <tr>
                          <th colSpan="2">料金区分</th>
                        </tr>
                        <tr>
                          <td>16歳以上</td>
                          <td>
                            900円
                            <br />
                            （通常価格1,000円）
                          </td>
                        </tr>
                        <tr>
                          <td>15歳以下</td>
                          <td>
                            500 円 ※<sup>3</sup>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="btn-wrap mt-5 mb-5">
                    <a href="/obm/89park/" className="btn-regular">
                      <span>詳細・注意事項はこちら</span>
                      <svg
                        viewBox="0 0 490.8 490.8"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M135.685,3.128c-4.237-4.093-10.99-3.975-15.083,0.262c-3.992,4.134-3.992,10.687,0,14.82l227.115,227.136L120.581,472.461c-4.237,4.093-4.354,10.845-0.262,15.083c4.093,4.237,10.845,4.354,15.083,0.262c0.089-0.086,0.176-0.173,0.262-0.262l234.667-234.667c4.164-4.165,4.164-10.917,0-15.083L135.685,3.128z" />
                        <path d="M128.133,490.68c-5.891,0.011-10.675-4.757-10.686-10.648c-0.005-2.84,1.123-5.565,3.134-7.571l227.136-227.115L120.581,18.232c-4.171-4.171-4.171-10.933,0-15.104c4.171-4.171,10.933-4.171,15.104,0l234.667,234.667c4.164,4.165,4.164,10.917,0,15.083L135.685,487.544C133.685,489.551,130.967,490.68,128.133,490.68z" />
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
              <p className="list-mark">
                ※<sup>1</sup> 3歳以下無料（要保護者同伴）
              </p>
              <p className="list-mark">
                ※<sup>2</sup>{' '}
                3歳以下無料。12歳以下のご利用には要保護者同伴（大人チケットの購入が必要）
                <br />
                保護者1名に対しお子様3名までご利用可能
              </p>
              <p className="list-mark">
                ※<sup>3</sup>{' '}
                6歳以下無料。12歳以下のご利用には要保護者同伴（大人チケットの購入が必要）
                <br />
                保護者1名に対しお子様3名までご利用可能
              </p>
              <p className="list-mark">
                ※
                添乗員・バスガイド・バス運転手・同行カメラマンは招待PASSを発行いたします
              </p>
              <p className="list-mark">
                ※ 2025年3月以降の料金については変更の場合がございます。
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="section">
        <div className="container">
          <h3 className="title is-3">
            <span>注意事項について</span>
          </h3>
          <div className="inner-slim">
            <p className="">
              BOSS E・ZO
              FUKUOKA館内（アトラクション出入口）での集合、説明等は禁止いたします。
            </p>
            <p className="">
              各アトラクションでの食べ物の持ち込み、アクティビティ利用中の飲食はご遠慮ください。
            </p>
            <p>
              ご飲酒後は安全上の理由によりアクティビティは体験いただけませんのでご注意ください。
            </p>
            <p>お手回り品の放置はご遠慮ください。ロッカーをご利用ください。</p>
          </div>
        </div>
      </section>{' '}
      <section className="section">
        <div className="container">
          <h4 className="title is-3 pc-mb-6 sp-mb0">
            <span>レストラン情報</span>
          </h4>
          <div className="inner-slim trans pb-0">
            <div className="slime-table">
              <div className="ticket-table">
                <div className="">
                  <h4 className="floor-ttl title is-6 fwb tac mb-4 sp-mb0">
                    <span className="floorNo mb0">
                      <span>3</span>
                      <span>F</span>
                    </span>
                    <a
                      href="/the-foodhall/mlbcafe-fukuoka/"
                      className="tx-link"
                    >
                      MLB café FUKUOKA
                    </a>
                  </h4>
                  <div className="flex mt-5">
                    <Image
                      src="img-group-usage-10.jpg"
                      className="half sp-mb10"
                    />
                    <div className="half flex-justify-center">
                      <p className="text is-2 is-3-sp mb-2">
                        本場 MLB の世界観が味わえる、MLB
                        公認の日本唯一のアメリカンレストランです。{' '}
                      </p>
                      <p className="text is-2 is-3-sp mb-2">
                        100 名を超える大人数でのご利用が可能です。
                      </p>
                      <p className="text is-2 is-3-sp">
                        大画面モニターや音響を活用した貸切も OK !
                      </p>
                    </div>
                  </div>
                  <div className="btn-wrap mt-5 mb-5">
                    <a
                      href="/the-foodhall/mlbcafe-fukuoka/"
                      className="btn-regular"
                    >
                      <span>詳細・注意事項はこちら</span>
                      <svg
                        viewBox="0 0 490.8 490.8"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M135.685,3.128c-4.237-4.093-10.99-3.975-15.083,0.262c-3.992,4.134-3.992,10.687,0,14.82l227.115,227.136L120.581,472.461c-4.237,4.093-4.354,10.845-0.262,15.083c4.093,4.237,10.845,4.354,15.083,0.262c0.089-0.086,0.176-0.173,0.262-0.262l234.667-234.667c4.164-4.165,4.164-10.917,0-15.083L135.685,3.128z" />
                        <path d="M128.133,490.68c-5.891,0.011-10.675-4.757-10.686-10.648c-0.005-2.84,1.123-5.565,3.134-7.571l227.136-227.115L120.581,18.232c-4.171-4.171-4.171-10.933,0-15.104c4.171-4.171,10.933-4.171,15.104,0l234.667,234.667c4.164,4.165,4.164,10.917,0,15.083L135.685,487.544C133.685,489.551,130.967,490.68,128.133,490.68z" />
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section">
        <div className="container">
          <h3 className="title is-3">
            <span>その他施設について</span>
          </h3>
          <div className="inner-slim">
            <div className="btn-wrap">
              <a href="/information/" className="btn-regular wide">
                <span>施設案内</span>
                <svg
                  viewBox="0 0 490.8 490.8"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M135.685,3.128c-4.237-4.093-10.99-3.975-15.083,0.262c-3.992,4.134-3.992,10.687,0,14.82l227.115,227.136L120.581,472.461c-4.237,4.093-4.354,10.845-0.262,15.083c4.093,4.237,10.845,4.354,15.083,0.262c0.089-0.086,0.176-0.173,0.262-0.262l234.667-234.667c4.164-4.165,4.164-10.917,0-15.083L135.685,3.128z" />
                  <path d="M128.133,490.68c-5.891,0.011-10.675-4.757-10.686-10.648c-0.005-2.84,1.123-5.565,3.134-7.571l227.136-227.115L120.581,18.232c-4.171-4.171-4.171-10.933,0-15.104c4.171-4.171,10.933-4.171,15.104,0l234.667,234.667c4.164,4.165,4.164,10.917,0,15.083L135.685,487.544C133.685,489.551,130.967,490.68,128.133,490.68z" />
                </svg>
              </a>
            </div>
            <p className="tac mt-5">
              <a
                href="https://dometour.softbankhawks.co.jp/contact/"
                target="_blank"
                rel="noopener noreferrer"
                className="tx-link"
              >
                ドームツアーのお問い合わせ
              </a>
            </p>
            <p className="tac mt-5">
              <a
                href="https://yoshimoto.funity.jp/question/question-5911/"
                target="_blank"
                rel="noopener noreferrer"
                className="tx-link"
              >
                よしもと劇場のお問い合わせ
              </a>
            </p>
            <p className="tac mt-5">
              <a
                href="http://www.hkt48.jp/inquiry"
                target="_blank"
                rel="noopener noreferrer"
                className="tx-link"
              >
                HKT劇場のお問い合わせ
              </a>
            </p>
            <p className="tac mt-5">
              MLB café
              FUKUOKAのみのご利用の場合は、直接店舗（092-687-0428）へお問い合わせください。
            </p>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default ticketHowPage;
