/**
 * Type: ページ
 * What: ファイヤーホール4000
 */
import React, { useEffect } from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { scroller } from 'react-scroll';
import Scrollbar from 'smooth-scrollbar';
import Layout from '../../components/Layout';
import SEO from '../../components/Seo';
import BreadcrumbComponent from '../../components/Breadcrumb';
import SimpleSwiper from '../../components/Slider.fade';
import Image from '../../util/Image';
import { Btn, Button, Text, Title } from '../../components/Btn';
import addImg01 from '../../images/foodhall/menu/menu-firewhole3.jpg';
import addImg02 from '../../images/foodhall/menu/menu-firewhole4.jpg';
import addImg03 from '../../images/foodhall/menu/menu-firewhole5.jpg';

const pageTitle = 'The FOODHALL（フードホール）';
const pageDescription =
  '西日本初出店！日本を代表する中国料理人菰田欣也がオーナーシェフを務める、火鍋を主軸とした中国料理店です。';
const page2Title = 'ファイヤーホール4000';
const pageSlug = 'foodhall';

const sliderDataobj = [
  {
    filename: 'main-firewhole1.jpg',
  },
  {
    filename: 'main-firewhole2.jpg',
  },
];

const Detail = {
  name: page2Title, // 店名
  description:
    '『ファイヤーホール4000』は、日本を代表する中国料理人菰田欣也がオーナーシェフを務める、火鍋を主軸とした中国料理店です。', // 説明
  item1: '火鍋スープ2種類セット', // 代表メニュー1商品名
  image1: 'menu-firewhole.jpg', // 代表メニュー1画像
  item2: '菰田欣也特製　麻婆豆腐', // 代表メニュー2商品名
  image2: 'menu-firewhole2.jpg', // 代表メニュー2画像
  mainMenu: '火鍋、中華料理', // 代表メニュー
  time:
    '(基本営業時間)11:00～23:00 　※みずほPayPayドーム開催イベントにより営業時間の変更あり', // 営業時間
  tel: '092-707-4158', // 電話
  reserve: '可※詳しくはお問合せください。', // 予約
  seat: '35席', // 座席数
  pay: '現金、各種クレジットカード、PayPay　ほか', // 対応決済
  other:
    '英語メニュー有、禁煙、Wi-Fi、お子様椅子あり、テイクアウト可、ベビーカー可', // その他
  url: '', // URL
  sns: ['', '', ''], // SNS
  caution: '特になし', // 注意事項
  holidayTitle: '定休日', // 定休日タイトル
  holidayText: 'なし', // 定休日に表示するテキスト
};

// Data Props Template
const Template = () => {
  const data = useStaticQuery(graphql`
    {
      allWordpressWpDeal(filter: { deal_category: { eq: 17 } }, limit: 20) {
        nodes {
          title
          excerpt
          wordpress_id
        }
      }
    }
  `);
  const deal = data.allWordpressWpDeal.nodes;
  // 初回DOM
  useEffect(() => {
    const scrollbarOptions = {
      damping: 0.125,
      renderByPixels: true,
      alwaysShowTracks: true,
    };
    deal.length &&
      Scrollbar.init(document.querySelector('#js-scrollbar'), scrollbarOptions);
  }, [deal.length]);
  return (
    <Layout pageSlug={pageSlug} bg="bg-2">
      <SEO title={page2Title} description={pageDescription} />
      <BreadcrumbComponent
        hierarchy={3}
        bread2url="the-foodhall/"
        bread2title={pageTitle}
        bread3title={page2Title}
      />
      <section className="pageMain bs-2">
        <div className="kv-wrap bg-2">
          <div className="kv-inner">
            <SimpleSwiper data={sliderDataobj} />
          </div>
          <div className="container under">
            <div className="inner">
              <div className="pageMain-header">
                <div className="floorNo c-2">
                  <span>3</span>
                  <span>F</span>
                </div>
                <h2 className="ttl">{page2Title}</h2>
              </div>

              <div className="overview">
                <p className="text">{Detail.description}</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section bg-13" id="menu">
        <div className="container">
          <h3 className="headline">
            <span>Menu</span>
          </h3>
          <div className="sc-columns mb30">
            {Detail.image1 && (
              <div
                className="col col-6 floorbc2 none"
                data-sal="slide-up"
                data-sal-easing="ease-out-expo"
              >
                <div className="col-inner">
                  <div className="col-image">
                    <figure className="image">
                      <Image filename={Detail.image1} />
                    </figure>
                  </div>
                  <div className="col-content">
                    <h3 className="ttl is-size-6 has-text-align-center">
                      {Detail.item1}
                    </h3>
                  </div>
                </div>
              </div>
            )}
            {Detail.image2 && (
              <div
                className="col col-6 floorbc2 none"
                data-sal="slide-up"
                data-sal-easing="ease-out-expo"
              >
                <div className="col-inner">
                  <div className="col-image">
                    <figure className="image">
                      <Image filename={Detail.image2} />
                    </figure>
                  </div>
                  <div className="col-content">
                    <h3 className="ttl is-size-6 has-text-align-center">
                      {Detail.item2}
                    </h3>
                  </div>
                </div>
              </div>
            )}
          </div>
          <h4 className="title is-4 c-2">主なメニュー</h4>
          <p className="has-text-align-center is-size-6">{Detail.mainMenu}</p>

          <div className="btn-wrap mt-6 mb-3">
            <a
              href="/files/food/menu-firewhole2024.pdf"
              className="btn-regular btn-regular"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span>メニュー（PDF）</span>
              <svg viewBox="0 0 490.8 490.8" xmlns="http://www.w3.org/2000/svg">
                <path d="M135.685,3.128c-4.237-4.093-10.99-3.975-15.083,0.262c-3.992,4.134-3.992,10.687,0,14.82l227.115,227.136L120.581,472.461c-4.237,4.093-4.354,10.845-0.262,15.083c4.093,4.237,10.845,4.354,15.083,0.262c0.089-0.086,0.176-0.173,0.262-0.262l234.667-234.667c4.164-4.165,4.164-10.917,0-15.083L135.685,3.128z" />
                <path d="M128.133,490.68c-5.891,0.011-10.675-4.757-10.686-10.648c-0.005-2.84,1.123-5.565,3.134-7.571l227.136-227.115L120.581,18.232c-4.171-4.171-4.171-10.933,0-15.104c4.171-4.171,10.933-4.171,15.104,0l234.667,234.667c4.164,4.165,4.164,10.917,0,15.083L135.685,487.544C133.685,489.551,130.967,490.68,128.133,490.68z" />
              </svg>
            </a>
          </div>
          {/*
          <div className="btn-wrap mb-3">
            <a
              href="/files/food/menu-firewhole.pdf"
              className="btn-regular btn-regular"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span>グランドメニュー（PDF）</span>
              <svg viewBox="0 0 490.8 490.8" xmlns="http://www.w3.org/2000/svg">
                <path d="M135.685,3.128c-4.237-4.093-10.99-3.975-15.083,0.262c-3.992,4.134-3.992,10.687,0,14.82l227.115,227.136L120.581,472.461c-4.237,4.093-4.354,10.845-0.262,15.083c4.093,4.237,10.845,4.354,15.083,0.262c0.089-0.086,0.176-0.173,0.262-0.262l234.667-234.667c4.164-4.165,4.164-10.917,0-15.083L135.685,3.128z" />
                <path d="M128.133,490.68c-5.891,0.011-10.675-4.757-10.686-10.648c-0.005-2.84,1.123-5.565,3.134-7.571l227.136-227.115L120.581,18.232c-4.171-4.171-4.171-10.933,0-15.104c4.171-4.171,10.933-4.171,15.104,0l234.667,234.667c4.164,4.165,4.164,10.917,0,15.083L135.685,487.544C133.685,489.551,130.967,490.68,128.133,490.68z" />
              </svg>
            </a>
          </div>
          <div className="btn-wrap mb-3">
            <a
              href="/files/food/menu-drink-firewhole.pdf"
              className="btn-regular btn-regular"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span>ドリンクメニュー（PDF）</span>
              <svg viewBox="0 0 490.8 490.8" xmlns="http://www.w3.org/2000/svg">
                <path d="M135.685,3.128c-4.237-4.093-10.99-3.975-15.083,0.262c-3.992,4.134-3.992,10.687,0,14.82l227.115,227.136L120.581,472.461c-4.237,4.093-4.354,10.845-0.262,15.083c4.093,4.237,10.845,4.354,15.083,0.262c0.089-0.086,0.176-0.173,0.262-0.262l234.667-234.667c4.164-4.165,4.164-10.917,0-15.083L135.685,3.128z" />
                <path d="M128.133,490.68c-5.891,0.011-10.675-4.757-10.686-10.648c-0.005-2.84,1.123-5.565,3.134-7.571l227.136-227.115L120.581,18.232c-4.171-4.171-4.171-10.933,0-15.104c4.171-4.171,10.933-4.171,15.104,0l234.667,234.667c4.164,4.165,4.164,10.917,0,15.083L135.685,487.544C133.685,489.551,130.967,490.68,128.133,490.68z" />
              </svg>
            </a>
          </div>
          */}

          <p className="has-text-align-center is-size-7">
            ※掲載のメニューは一例です。
          </p>
          <div
            className="columns add-menus is-mobile is-multiline"
            data-sal="slide-up"
            data-sal-easing="ease-out-expo"
          >
            <div className="column is-3-desktop is-6-mobile">
              <img src={addImg01} alt="" />
            </div>
            <div className="column is-3-desktop is-6-mobile">
              <img src={addImg02} alt="" />
            </div>
            <div className="column is-3-desktop is-6-mobile">
              <img src={addImg03} alt="" />
            </div>
          </div>
        </div>
      </section>
      {deal.length ? (
        <section className="section sc-otokuinfo" id="otokuinfo">
          <div className="container">
            <h3 className="headline">
              <span className="c-17">お得情報</span>
            </h3>

            <div className="sc-otokuinfo-wrap scrollbar" id="js-scrollbar">
              {deal.map((item) => (
                <dl key={item.title}>
                  <dt>お得情報</dt>
                  <dd className="bg-2">
                    <Link to={`/deal/${item.wordpress_id}`}>
                      <div dangerouslySetInnerHTML={{ __html: item.excerpt }} />
                      <Image src="breadcrumb-arrow3.svg" />
                    </Link>
                  </dd>
                </dl>
              ))}
            </div>
          </div>
        </section>
      ) : null}
      <section className="section bg-15" id="storeInformation">
        <div className="container">
          <h3 className="headline">
            <span className="c-17">店舗情報</span>
          </h3>

          <div className="content-box bg-16 mb60">
            <table className="table is-narrow is-fullwidth">
              <tbody>
                {Detail.holidayText && (
                  <tr>
                    {Detail.holidayTitle && <th>{Detail.holidayTitle}</th>}
                    <td className="tal">{Detail.holidayText}</td>
                  </tr>
                )}
                {Detail.tel ? (
                  <tr>
                    <th>電話番号</th>
                    <td className="tal">{Detail.tel}</td>
                  </tr>
                ) : null}
                <tr>
                  <th>予約</th>
                  <td className="tal">{Detail.reserve}</td>
                </tr>
                <tr>
                  <th>座席数</th>
                  <td className="tal">{Detail.seat}</td>
                </tr>
                <tr>
                  <th>決済情報</th>
                  <td className="tal">{Detail.pay}</td>
                </tr>
                <tr>
                  <th>店舗公式サイト</th>
                  <td className="tal">準備中</td>
                  {/* <td className="tal">
                    {Detail.url ? (
                      <p className="underline-link">
                        <a
                          href={Detail.url}
                          className=""
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {Detail.url}
                        </a>
                      </p>
                    ) : (
                      ''
                    )}
                    <ul className="sns">
                      {Detail.sns[0] && (
                        <li>
                          <a
                            href={Detail.sns[0]}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="fb"
                          >
                            <Image
                              src="icon-fb.png"
                              alt="facebook"
                              width="22px"
                              height="22px"
                            />
                          </a>
                        </li>
                      )}
                      {Detail.sns[1] && (
                        <li>
                          <a
                            href={Detail.sns[1]}
                            className="tw"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <Image
                              src="icon-tw.png"
                              alt="twitter"
                              target="_blank"
                              width="22px"
                              height="22px"
                            />
                          </a>
                        </li>
                      )}
                      {Detail.sns[2] && (
                        <li>
                          <a
                            href={Detail.sns[2]}
                            className="ig"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <Image
                              src="ig.svg"
                              alt="instagram"
                              target="_blank"
                              width="22px"
                              height="22px"
                            />
                          </a>
                        </li>
                      )}
                    </ul>
                  </td> */}
                </tr>
              </tbody>
            </table>
          </div>
          <div className="btn-wrap mb60">
            <Button href="/the-foodhall/">店舗一覧へ戻る</Button>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Template;
