/**
 * Type: ページ
 * What: 施設の営業時間
 */
import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import DatePicker, { registerLocale } from 'react-datepicker';
import ja from 'date-fns/locale/ja';

import axios from 'axios';
import moment from 'moment/moment';

import { scroller } from 'react-scroll';
import Layout from '../components/Layout';
import SEO from '../components/Seo';
import BreadcrumbComponent from '../components/Breadcrumb';
import Image from '../util/Image';
import 'react-datepicker/dist/react-datepicker.css';

registerLocale('ja', ja);

const pageTitle = '施設の営業時間';
const pageDescription =
  'E・ZO FUKUOKA（イーゾ フクオカ）の施設の営業時間情報です。';
const pageSlug = 'schedule';
const SCHEDULE_ENDPOINT = '/api-data/schedule.json';

const EVENT_HALL = [
  {
    // イベントホール
    name: 'SPITZ,NOW! 〜ロック大陸の物語VR展〜',
    img: 'suchedule-spitznow.jpg',
    link: 'https://e-zofukuoka.com/news/space/2024122529373/',
  },
  {
    // イベントホール2
    name: '魔法の美術館',
    img: 'top-mahou-museum.jpg',
    link: '/special/mahou_museum/',
  },
  {
    // イベントホール3
    name: 'Sanrio characters Dream!ng Park',
    img: 'schedule-sanrio.jpg',
    link: '/dreamingpark/',
  },
];

const foodArray = [
  '2021/10/1',
  '2021/10/2',
  '2021/10/3',
  '2021/10/4',
  '2021/10/5',
  '2021/10/6',
  '2021/10/7',
  '2021/10/8',
  '2021/10/9',
  '2021/10/10',
  '2021/10/11',
  '2021/10/12',
  '2021/10/13',
  '2021/10/14',
];

const Schedule = () => {
  const [apiData, setApiData] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [facilities, setFacilities] = useState([]);
  const [dateChanged, setDateChanged] = useState(
    new Date().toLocaleDateString()
  );
  const [selectedWorkTime, setSelectedWorkTime] = useState([]);

  useEffect(() => {
    dataFetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dataFetch = async () => {
    const result = await axios.get(SCHEDULE_ENDPOINT);

    setApiData(result.data);
    setFacilities(result.data[0].slice(1));
    defList(result.data);
  };

  const changeDate = () => {
    setDateChanged(selectedDate.toLocaleDateString());
    const dateChangedStr = `${moment(selectedDate.toLocaleDateString()).format(
      'YYYY/M/D'
    )}`;
    const dateInfo = apiData.filter((row) => row[0] === dateChangedStr);
    setSelectedWorkTime(dateInfo);
    scrollToTarget('schedules', -68);
  };

  const defList = (api) => {
    const dateChangedStr = `${moment(selectedDate.toLocaleDateString()).format(
      'YYYY/M/D'
    )}`;
    const dateInfo = api.filter((row) => row[0] === dateChangedStr);
    setSelectedWorkTime(dateInfo);
  };

  const MLB_TIME = selectedWorkTime[0] && selectedWorkTime[0][1];
  const MLB_END = selectedWorkTime[0] && selectedWorkTime[0][2];
  const FOOD_TIME = selectedWorkTime[0] && selectedWorkTime[0][3];
  const FOOD_END = selectedWorkTime[0] && selectedWorkTime[0][4];
  const OBM_TIME = selectedWorkTime[0] && selectedWorkTime[0][5];
  const OBM_ENT = selectedWorkTime[0] && selectedWorkTime[0][6];
  const OBM_END = selectedWorkTime[0] && selectedWorkTime[0][7];
  const EVENT_TIME = selectedWorkTime[0] && selectedWorkTime[0][8];
  const EVENT_ENT = selectedWorkTime[0] && selectedWorkTime[0][9];
  const EVENT_END = selectedWorkTime[0] && selectedWorkTime[0][10];
  const TEAMLAB_TIME = selectedWorkTime[0] && selectedWorkTime[0][11];
  const TEAMLAB_ENT = selectedWorkTime[0] && selectedWorkTime[0][12];
  const TEAMLAB_END = selectedWorkTime[0] && selectedWorkTime[0][13];
  const EVENT2_TIME = selectedWorkTime[0] && selectedWorkTime[0][14];
  const EVENT2_ENT = selectedWorkTime[0] && selectedWorkTime[0][15];
  const EVENT2_END = selectedWorkTime[0] && selectedWorkTime[0][16];
  const VWORLD_TIME = selectedWorkTime[0] && selectedWorkTime[0][17];
  const VWORLD_ENT = selectedWorkTime[0] && selectedWorkTime[0][18];
  const VWORLD_END = selectedWorkTime[0] && selectedWorkTime[0][19];
  const SUBEZO_TIME = selectedWorkTime[0] && selectedWorkTime[0][20];
  const SUBEZO_ENT = selectedWorkTime[0] && selectedWorkTime[0][21];
  const SUBEZO_END = selectedWorkTime[0] && selectedWorkTime[0][22];
  const NOBOZO_TIME = selectedWorkTime[0] && selectedWorkTime[0][23];
  const NOBOZO_ENT = selectedWorkTime[0] && selectedWorkTime[0][24];
  const NOBOZO_END = selectedWorkTime[0] && selectedWorkTime[0][25];
  const TSURIZO_TIME = selectedWorkTime[0] && selectedWorkTime[0][26];
  const TSURIZO_ENT = selectedWorkTime[0] && selectedWorkTime[0][27];
  const TSURIZO_END = selectedWorkTime[0] && selectedWorkTime[0][28];
  const EVENT3_TIME = selectedWorkTime[0] && selectedWorkTime[0][29];
  const EVENT3_ENT = selectedWorkTime[0] && selectedWorkTime[0][30];
  const EVENT3_END = selectedWorkTime[0] && selectedWorkTime[0][31];
  const INFORMATION_TIME = selectedWorkTime[0] && selectedWorkTime[0][32];

  const scrollToTarget = (elem, offset) => {
    const $offset = offset || -110;
    scroller.scrollTo(elem, {
      duration: 1200,
      delay: 0,
      offset: $offset,
      smooth: 'easeOutQuint',
    });
  };

  return (
    <Layout pageSlug={pageSlug}>
      <SEO title={pageTitle} description={pageDescription} />
      <BreadcrumbComponent hierarchy={2} bread2title={pageTitle} />
      <section className="section">
        <div className="container">
          <h3 className="headline">
            <span>{pageTitle}</span>
          </h3>
          <div className="inner-slim">
            <article>
              <p>
                ご希望の日程を、カレンダーより選択ください。
                <br />
                選択された日程の施設ごとの営業時間をご覧いただけます。
              </p>
              <p className="list-mark">
                <span className="attention">※</span>
                屋上施設は営業時間中でも急な天候変化等により
                運行を取りやめる場合がございます。
                <br />
                当日の運行状況は、
                <Link to="/zekkei-brothers/" className="tx-link">
                  絶景3兄弟 SMBC日興証券
                </Link>
                のページにてご確認ください。
              </p>
              <p className="list-mark">
                <span className="attention">※</span>
                <Link to="/yoshimoto-fukuoka/" className="tx-link">
                  よしもと福岡 大和証券劇場
                </Link>
                、
                <Link to="/hkt/" className="tx-link">
                  HKT48 劇場
                </Link>
                の営業時間はそれぞれのページでご確認ください。
              </p>
            </article>
            <article>
              <div className="date-picker">
                <DatePicker
                  dateFormat="yyyy年MM月dd日"
                  selected={selectedDate}
                  minDate={new Date()}
                  maxDate={
                    new Date(apiData[0] && apiData[apiData.length - 1][0])
                  }
                  locale="ja"
                  onChange={(date) => setSelectedDate(date)}
                  showDisabledMonthNavigation
                />
                <button
                  type="button"
                  className="btn-regular btn-primary"
                  onClick={changeDate}
                >
                  <span>日付を選択</span>
                </button>
              </div>
            </article>
          </div>
        </div>
      </section>
      <section className="section" id="schedules">
        <div className="container">
          <div className="inner">
            <h4 className="headline mb-6" id="scheduleResult">
              <span>{moment(dateChanged).format('YYYY年MM月DD日')}</span>
              <span>の営業時間情報</span>
            </h4>
            <div className="sc-columns">
              {selectedWorkTime.length ? (
                <>
                  {SUBEZO_TIME !== '' ? (
                    <div className="col col-4 schedules">
                      <Link to="/zekkei-brothers/subezo/">
                        <div className="col-inner">
                          <div className="col-image">
                            <figure className="image">
                              <Image filename="img-attraction-subezo.jpg" />
                            </figure>
                          </div>
                          <div className="col-content">
                            <h5 className="schedule-title">
                              すべZO SMBC日興証券
                            </h5>
                            <p className="schedule-time">{SUBEZO_TIME}</p>
                            {SUBEZO_END !== '' ? (
                              <p className="schedule-limit">
                                (販売終了時間 {SUBEZO_END})
                              </p>
                            ) : null}
                            {SUBEZO_ENT !== '' ? (
                              <p className="schedule-limit">
                                (最終入場時間 {SUBEZO_ENT})
                              </p>
                            ) : null}
                          </div>
                        </div>
                      </Link>
                    </div>
                  ) : null}
                  {TSURIZO_TIME !== '' ? (
                    <div className="col col-4 schedules">
                      <Link to="/zekkei-brothers/tsurizo/">
                        <div className="col-inner">
                          <div className="col-image">
                            <figure className="image">
                              <Image filename="kv-zekkei-tsurizo.jpg" />
                            </figure>
                          </div>
                          <div className="col-content">
                            <h5 className="schedule-title">
                              つりZO SMBC日興証券
                            </h5>
                            <p className="schedule-time">{TSURIZO_TIME}</p>
                            {TSURIZO_END !== '' ? (
                              <p className="schedule-limit">
                                (販売終了時間 {TSURIZO_END})
                              </p>
                            ) : null}
                            {TSURIZO_ENT !== '' ? (
                              <p className="schedule-limit">
                                (最終入場時間 {TSURIZO_ENT})
                              </p>
                            ) : null}
                            <p className="schedule-limit">
                              ・天候などにより予告なく休止する場合がございます。
                            </p>
                          </div>
                        </div>
                      </Link>
                    </div>
                  ) : null}
                  {NOBOZO_TIME !== '' ? (
                    <div className="col col-4 schedules">
                      <Link to="/zekkei-brothers/nobozo/">
                        <div className="col-inner">
                          <div className="col-image">
                            <figure className="image">
                              <Image filename="kv-zekkei-nobozo.jpg" />
                            </figure>
                          </div>
                          <div className="col-content">
                            <h5 className="schedule-title">
                              のぼZO SMBC日興証券
                            </h5>
                            <p className="schedule-time">{NOBOZO_TIME}</p>
                            {NOBOZO_END !== '' ? (
                              <p className="schedule-limit">
                                (販売終了時間 {NOBOZO_END})
                              </p>
                            ) : null}
                            {NOBOZO_ENT !== '' ? (
                              <p className="schedule-limit">
                                (最終入場時間 {NOBOZO_ENT})
                              </p>
                            ) : null}
                            <p className="schedule-limit">
                              ・天候などにより予告なく休止する場合がございます。
                            </p>
                          </div>
                        </div>
                      </Link>
                    </div>
                  ) : null}
                  {EVENT3_TIME !== '' ? (
                    <div className="col col-4 schedules">
                      <Link to={EVENT_HALL[2].link}>
                        <div className="col-inner">
                          <div className="col-image">
                            <figure className="image">
                              <Image filename={EVENT_HALL[2].img} />
                            </figure>
                          </div>
                          <div className="col-content">
                            <h5 className="schedule-title">
                              {EVENT_HALL[2].name}
                            </h5>
                            <p className="schedule-time">{EVENT3_TIME}</p>
                            {EVENT3_END !== '' ? (
                              <p className="schedule-limit">
                                (販売終了時間 {EVENT3_END})
                              </p>
                            ) : null}
                            {EVENT3_ENT !== '' ? (
                              <p className="schedule-limit">
                                (最終入場時間 {EVENT3_ENT})
                              </p>
                            ) : null}
                          </div>
                        </div>
                      </Link>
                    </div>
                  ) : null}
                  {VWORLD_TIME !== '' ? (
                    <div className="col col-4 schedules">
                      <Link to="/v-world/">
                        <div className="col-inner">
                          <div className="col-image">
                            <figure className="image">
                              <Image filename="img-attraction-floor76.jpg" />
                            </figure>
                          </div>
                          <div className="col-content">
                            <h5 className="schedule-title">
                              V-World AREA クレディ・アグリコル
                            </h5>
                            <p className="schedule-time">{VWORLD_TIME}</p>
                            {VWORLD_END !== '' ? (
                              <p className="schedule-limit">
                                (販売終了時間 {VWORLD_END})
                              </p>
                            ) : null}
                            {VWORLD_ENT !== '' ? (
                              <p className="schedule-limit">
                                (最終入場時間 {VWORLD_ENT})
                              </p>
                            ) : null}
                          </div>
                        </div>
                      </Link>
                    </div>
                  ) : null}
                  {EVENT_TIME !== '' ? (
                    <div className="col col-4 schedules">
                      <a
                        href={EVENT_HALL[0].link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div className="col-inner">
                          <div className="col-image">
                            <figure className="image">
                              <Image filename={EVENT_HALL[0].img} />
                            </figure>
                          </div>
                          <div className="col-content">
                            <h5 className="schedule-title">
                              {EVENT_HALL[0].name}
                            </h5>
                            <p className="schedule-time">{EVENT_TIME}</p>
                            {EVENT_END !== '' ? (
                              <p className="schedule-limit">
                                (販売終了時間 {EVENT_END})
                              </p>
                            ) : null}
                            {EVENT_ENT !== '' ? (
                              <p className="schedule-limit">
                                (最終入場時間 {EVENT_ENT})
                              </p>
                            ) : null}
                          </div>
                        </div>
                      </a>
                    </div>
                  ) : null}
                  {EVENT2_TIME !== '' ? (
                    <div className="col col-4 schedules">
                      <Link to={EVENT_HALL[1].link}>
                        <div className="col-inner">
                          <div className="col-image">
                            <figure className="image">
                              <Image filename={EVENT_HALL[1].img} />
                            </figure>
                          </div>
                          <div className="col-content">
                            <h5 className="schedule-title">
                              {EVENT_HALL[1].name}
                            </h5>
                            <p className="schedule-time">{EVENT2_TIME}</p>
                            {EVENT2_END !== '' ? (
                              <p className="schedule-limit">
                                (販売終了時間 {EVENT2_END})
                              </p>
                            ) : null}
                            {EVENT2_ENT !== '' ? (
                              <p className="schedule-limit">
                                (最終入場時間 {EVENT2_ENT})
                              </p>
                            ) : null}
                          </div>
                        </div>
                      </Link>
                    </div>
                  ) : null}
                  {TEAMLAB_TIME !== '' ? (
                    <div className="col col-4 schedules">
                      <Link to="/teamlabforest/">
                        <div className="col-inner">
                          <div className="col-image">
                            <figure className="image">
                              <Image filename="schedule-teamlabforest.jpg" />
                            </figure>
                          </div>
                          <div className="col-content">
                            <h5 className="schedule-title">
                              チームラボフォレスト 福岡 – SBI証券
                            </h5>
                            <p className="schedule-time">{TEAMLAB_TIME}</p>
                            {TEAMLAB_END !== '' ? (
                              <p className="schedule-limit">
                                (販売終了時間 {TEAMLAB_END})
                              </p>
                            ) : null}
                            {TEAMLAB_ENT !== '' ? (
                              <p className="schedule-limit">
                                (最終入場時間 {TEAMLAB_ENT})
                              </p>
                            ) : null}
                          </div>
                        </div>
                      </Link>
                    </div>
                  ) : null}
                  {OBM_TIME !== '' ? (
                    <div className="col col-4 schedules">
                      <Link to="/obm/">
                        <div className="col-inner">
                          <div className="col-image">
                            <figure className="image">
                              <Image filename="schedule-obm.jpg" />
                            </figure>
                          </div>
                          <div className="col-content">
                            <h5 className="schedule-title">
                              王貞治ベースボールミュージアム Supported by DREAM
                              ORDER
                            </h5>
                            <p className="schedule-time">{OBM_TIME}</p>
                            {OBM_END !== '' ? (
                              <p className="schedule-limit">
                                (販売終了時間 {OBM_END})
                              </p>
                            ) : null}
                            {OBM_ENT !== '' ? (
                              <p className="schedule-limit">
                                (最終入場時間 {OBM_ENT})
                              </p>
                            ) : null}
                          </div>
                        </div>
                      </Link>
                    </div>
                  ) : null}
                  {MLB_TIME !== '' ? (
                    <div className="col col-4 schedules">
                      <Link to="/mlbcafe-fukuoka/">
                        <div className="col-inner">
                          <div className="col-image">
                            <figure className="image">
                              <Image filename="main-mlbcafe01.jpg" />
                            </figure>
                          </div>
                          <div className="col-content">
                            <h5 className="schedule-title">MLB café FUKUOKA</h5>
                            <p className="schedule-time">{MLB_TIME}</p>
                            {MLB_END !== '' ? (
                              <p className="schedule-limit">
                                (販売終了時間 {MLB_END})
                              </p>
                            ) : null}
                            {foodArray.includes(dateChanged) ? (
                              <p className="schedule-limit">
                                ・ラストオーダー: 20:30
                                <br />
                                ・貸切営業などにより営業時間が異なる場合がございます。
                              </p>
                            ) : (
                              <p className="schedule-limit">
                                ・ラストオーダー: 閉店1時間前
                                <br />
                                ・貸切営業などにより営業時間が異なる場合がございます。
                              </p>
                            )}
                          </div>
                        </div>
                      </Link>
                    </div>
                  ) : null}
                  {FOOD_TIME !== '' ? (
                    <div className="col col-4 schedules">
                      <Link to="/the-foodhall/">
                        <div className="col-inner">
                          <div className="col-image">
                            <figure className="image">
                              <Image filename="main-foodhall.jpg" />
                            </figure>
                          </div>
                          <div className="col-content">
                            <h5 className="schedule-title">
                              The FOODHALL（フードホール）
                            </h5>
                            <p className="schedule-time">{FOOD_TIME}</p>
                            {FOOD_END !== '' ? (
                              <p className="schedule-limit">
                                (販売終了時間 {FOOD_END})
                              </p>
                            ) : null}
                            {foodArray.includes(dateChanged) ? (
                              <div>
                                <p className="schedule-limit">
                                  ・ラストオーダー: 閉店30分前
                                  <br />
                                  ・一部営業時間が異なる店舗がございます。
                                </p>
                              </div>
                            ) : (
                              <p className="schedule-limit">
                                ・ラストオーダー: 閉店1時間前
                                <br />
                                ・一部営業時間・定休日が異なる店舗がございます。
                              </p>
                            )}
                          </div>
                        </div>
                      </Link>
                    </div>
                  ) : null}
                  {typeof INFORMATION_TIME !== 'undefined' &&
                  INFORMATION_TIME !== '' ? (
                    <div className="col col-4 schedules">
                      <div className="no-link">
                        <div className="col-inner">
                          <div className="col-image">
                            <figure className="image">
                              <Image filename="schedule-information.jpg" />
                            </figure>
                          </div>
                          <div className="col-content">
                            <h5 className="schedule-title">3F Information</h5>
                            <p className="schedule-time">{INFORMATION_TIME}</p>
                            <p className="schedule-limit">
                              ・みずほPayPayドーム開催のホークス主催試合、コンサート等のイベントにより営業時間が異なる場合がございます。
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </>
              ) : (
                <div className="col col-12">
                  <p className="has-text-align-center en is-size-4">
                    Loading...
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Schedule;
