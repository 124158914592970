// フッターフロアガイド
export const FOOTER_LINKS = [
  {
    pageName: 'RF 絶景3兄弟 SMBC日興証券',
    link: '/zekkei-brothers/',
    bold: false,
  },
  {
    pageName: '7F Sanrio characters Dream!ng Park（ドリパー）',
    link: '/dreamingpark/',
    bold: false,
  },
  {
    pageName: '7F よしもと福岡 大和証券劇場',
    link: '/yoshimoto-fukuoka/',
    bold: false,
  },
  {
    pageName: '6F V-World AREA クレディ・アグリコル',
    link: '/v-world/',
    bold: false,
  },
  // {
  //   pageName: '6F イベントホール',
  //   link: '/eventspace/',
  //   bold: false,
  // },
  {
    pageName: '6F SPITZ,NOW!～ロック大陸の物語VR展～（2/1から3/9）',
    link: 'https://e-zofukuoka.com/news/space/2024122529373/',
    bold: false,
    target: false,
  },
  {
    pageName: '6F トミカ・プラレールフェスティバル in FUKUOKA（3/20から4/6）',
    link: 'https://e-zofukuoka.com/news/space/2024122729288/',
    bold: false,
    target: false,
  },
  {
    pageName: '5F チームラボフォレスト 福岡 – SBI証券',
    link: '/teamlabforest/',
    bold: false,
  },
  {
    pageName:
      '4F 王貞治ベースボールミュージアム Supported by DREAM ORDER「89パーク」「ヒストリーゾーン」',
    link: '/obm/',
    bold: false,
  },
  {
    pageName: '3F The FOODHALL',
    link: '/the-foodhall/',
    bold: false,
  },
  {
    pageName: '1F HKT48 劇場',
    link: '/hkt/',
    bold: false,
  },
];

// PCヘッダーSPハンバーガーメニューの遊ぶ
export const PLAY_LINKS = [
  {
    pageName: '絶景3兄弟 SMBC日興証券',
    link: '/zekkei-brothers/',
    bold: false,
  },
  {
    pageName: 'Sanrio characters Dream!ng Park（ドリパー）',
    link: '/dreamingpark/',
    bold: false,
  },
  {
    pageName: 'V-World AREA クレディ・アグリコル',
    link: '/v-world/',
    bold: false,
  },
  {
    pageName: 'チームラボフォレスト 福岡 – SBI証券',
    link: '/teamlabforest/',
    bold: false,
  },
  {
    pageName: '王貞治ベースボールミュージアム Supported by DREAM ORDER',
    link: '/obm/',
    bold: false,
  },
  {
    pageName: 'ヒストリーゾーン',
    link: '/obm/museum/',
    bold: false,
    under: true,
  },
  {
    pageName: '89パーク',
    link: '/obm/89park/',
    bold: false,
    under: true,
  },
];

// PCヘッダーSPハンバーガーメニューの観る
export const WATCH_LINKS = [
  {
    pageName: 'よしもと福岡 大和証券劇場',
    link: '/yoshimoto-fukuoka/',
    bold: false,
  },
  {
    pageName: 'HKT48 劇場',
    link: '/hkt/',
    bold: false,
  },
];

// PCヘッダーSPハンバーガーメニューの食べる
export const EAT_LINKS = [
  {
    pageName: 'The FOODHALL',
    link: '/the-foodhall/',
    bold: false,
  },
  {
    pageName: 'MLB café FUKUOKA',
    link: '/the-foodhall/mlbcafe-fukuoka/',
    bold: false,
    under: true,
  },
  {
    pageName: 'ラーメン暖暮×らーめん酒場暖暮',
    link: '/the-foodhall/danbo/',
    bold: false,
    under: true,
  },
  {
    pageName: '焼肉 赤身 にくがとう',
    link: '/the-foodhall/nikugatou/',
    bold: false,
    under: true,
  },
  {
    pageName: '九州 はかた 大吉寿司',
    link: '/the-foodhall/daikichi/',
    bold: false,
    under: true,
  },
  {
    pageName: '肉山食堂',
    link: '/the-foodhall/nikuyama/',
    bold: false,
    under: true,
  },
  {
    pageName: 'ファイヤーホール4000',
    link: '/the-foodhall/firewhole/',
    bold: false,
    under: true,
  },
  {
    pageName: 'FUK COFFEE Seaside',
    link: '/the-foodhall/fuk-coffee/',
    bold: false,
    under: true,
  },
  {
    pageName: 'パーラーコマツ スタジアムサイド',
    link: '/the-foodhall/parlourcomatsu/',
    bold: false,
    under: true,
  },
];

// PCヘッダーSPハンバーガーメニューのイベントホール
export const EVENT_LINKS = [
  {
    pageName: 'ハリーとハニーのナゾトキクエスト（2/28まで）',
    link: 'https://e-zofukuoka.com/news/event/2024062126872/',
    bold: false,
    target: false,
  },
  {
    pageName: 'SPITZ,NOW!～ロック大陸の物語VR展～（2/1から3/9）',
    link: 'https://e-zofukuoka.com/news/space/2024122529373/',
    bold: false,
    target: false,
  },
  {
    pageName: 'トミカ・プラレールフェスティバル in FUKUOKA（3/20から4/6）',
    link: 'https://e-zofukuoka.com/news/space/2024122729288/',
    bold: false,
    target: false,
  },
];

// PCヘッダーメニュー
export const HEADER_LINKS = [
  {
    pageName: '団体ご利用',
    link: '/group/',
    classes: 'header-menu01',
  },
  {
    pageName: '施設案内',
    link: '/information/',
    classes: 'header-menu03',
  },
  {
    pageName: 'アクセス',
    link: '/access/',
    classes: 'header-menu02',
  },
  {
    pageName: 'チケット・料金',
    link: '/ticket/how/',
    classes: 'header-menu05',
  },
];

// SPハンバーガーメニュー
export const HBG_LINK = [
  {
    pageName: 'アクセス情報',
    link: '/access/',
    bold: true,
  },
  {
    pageName: 'TOPICS',
    link: '/news/',
    bold: true,
  },
  {
    pageName: '施設案内',
    link: '/information/',
    bold: true,
  },
  {
    pageName: '施設の営業時間',
    link: '/schedule/',
    bold: true,
  },
  {
    pageName: 'ご来館の皆様へ',
    link: '/rule/',
    bold: true,
  },
  {
    pageName: 'よくあるお問合せ',
    link: 'https://faq.softbankhawks.co.jp/category/show/128?site_domain=open',
    bold: true,
    target: true,
  },
  {
    pageName: '団体様ご利用案内',
    link: '/group/',
    bold: true,
  },
  {
    pageName: 'イベントホールご利用案内',
    link: '/eventhall/',
    bold: true,
  },
];
